// @media screen 
//   and (min-width: 1366px) 
//   and (max-width: 1439px) { 
//       html {
//         font-size:8.55px !important;
//       }
// }

// @media screen 
//   and (min-width: 1440px) 
//   and (max-width: 1519px) { 
//       html {
//         font-size:9px !important;
//       }
// }

// @media screen 
//   and (min-width: 1520px) 
//   and (max-width: 1599px) { 
//       html {
//         font-size:9.5px !important;
//       }
// }

// @media screen 
//   and (min-width: 1600px) 
//   and (max-width: 1679px) { 
//       html {
//         font-size:10px !important;
//       }
// }

// @media screen 
//   and (min-width: 1680px) 
//   and (max-width: 1759px) { 
//       html {
//         font-size:10.5px !important;
//       }
// }

// @media screen 
//   and (min-width: 1366px) {
//     .header-wrapper {
//       .nav-panel .nav-container {
//         .title {
//           font-size: 24px;
//         }
//       }
//     }
// }

// @media screen 
//   and (min-width: 1840px) 
//   and (max-width: 1919px) { 
//       html {
//         font-size:11.5px !important;
//       }
// }

// @media screen 
//   and (min-width: 1920px) { 
//       html {
//         font-size:12px !important;
//       }
// }



// Header CSS End

/* IPad pro Portrait and Landscape */
// @media only screen 
//   and (min-device-width: 1024px) 
//   and (max-device-width: 1366px)
//   and (-webkit-min-device-pixel-ratio: 2) {
//     .summary-wrapper {
//       height: auto !important;
//     }
// }

/* Portrait and Landscape */
// @media only screen 
//   and (min-device-width: 768px) 
//   and (max-device-width: 1024px) 
//   and (-webkit-min-device-pixel-ratio: 2) {
//     .summary-wrapper {
//       height: auto !important;
//     }
// }