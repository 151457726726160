/* You can add global styles to this file, and also import other style files */
@import "./base-font-sizes.scss";

// reset css
html, body {
    // background-color: #f2f5f9;
    background-color: #233851;
    min-height: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 62.5%;
    // font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-family: 'Manrope', sans-serif !important;
    font-weight: 400;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    color: #ffffff !important;
    font-variant-numeric: proportional-nums;
}

.ag-theme-balham,
.ag-theme-balham .ag-header, .mat-form-field, .mat-select {
    // font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    // font-family: 'Manrope', sans-serif;
    // font-family: 'IBM Plex Mono', monospace;
    font-weight: 300;
    font-style: normal;
    background-color:#1A2C44;
}
.cdk-overlay-container {
    // .cdk-overlay-dark-backdrop
    .cdk-overlay-backdrop + .cdk-global-overlay-wrapper {
        // -webkit-backdrop-filter: blur(10px);
        // backdrop-filter: blur(10px);
        // background-color: rgba(242, 245, 249, 0.5);
        background-color: rgba(23, 36, 62, 0.64);
    }
}

a {
    text-decoration: none;
}

.fas {
    width: auto;
    font-size: 14px;
    &.fa-arrow-down {
        padding-left: 1px !important;
    }
}

li {
    list-style: none;
    display: inline;
}
button:focus, li:focus {
    outline: none;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}

highcharts-chart, .charts-wrapper {
    height: 100%;
    width: 100%;
}
.highcharts-container {
    height: 100% !important;
    width: 100% !important;
}

::-webkit-scrollbar {
    width: 0.5rem;
    background-color: #ffffff;
    // border-radius: 100%;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    // border-radius: 100%;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background-color: rgba(91,103,134,0.4);
}

 .ag-theme-balham.total-row-table {
    .ag-root {
    border: 1px solid transparent;
    }
 }
 .top-grid {
     border-radius: 0 0 8px 8px;
     .ag-header {
         font-weight: bold;
         border-top: 1px solid #dae0e6;
         border-bottom: 1px solid #dae0e6;
         .ag-header-row {
            text-transform: capitalize;
            // color: #5f6c89;
            color: #ced2db;
            letter-spacing: .5px;
            // opacity: 0.8;
            opacity: 1;
            font-family: 'IBM Plex Mono', monospace;
            .ag-header-cell {
                &:first-child {
                    .ag-header-cell-label {
                        justify-content: flex-start;
                        padding-left: 2px;
                        // padding-left:6px;
                    }
                }
                .ag-header-cell-label {
                    justify-content: flex-end;
                    // padding-right: 6px;
                    // white-space: initial;
                    text-align: right;
                    padding-left: 10px;
                    .ag-header-cell-text {
                        // white-space: initial;
                        text-align: right;
                        // overflow: visible;
                        // text-overflow: initial;
                    }
                }
            }
         }
     }
     &.ag-theme-balham .ag-root {
        border: 1px solid transparent;
        border-radius: 0 0 8px 8px;
     }
    .table-row-wrapper {
        border: none !important;
        &.table-row-odd {
            background: #f2f5f9 !important;
            &:hover {
                background-color: #E2EEFF !important;
                transition: all 0.2s;
            }
        }
        &.table-row-even {
           background: #ffffff !important;
           &:hover {
                background-color: #E2EEFF !important;
                transition: all 0.2s;
            }
       }
       .table-cell {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            color: #5B6786;
            font-weight: 300;
            // padding-left: 5px;
            // padding-left: 2px;
            padding: 0;
            font-size: 10px;
            font-family: 'Manrope', sans-serif !important;
            &.values {
                font-family: 'IBM Plex Mono', monospace;
                justify-content: flex-end;
                // padding-right: 6px;
                // padding-right: 2px;
                padding: 0;
                &.number-cell {
                    padding: 0 2px;
                }
            }
            // &.renValue {
            //     // justify-content: flex-end;
            //     // padding:0;
            //     // max-width: 44px;
            // }
            &.null-value{
                justify-content: flex-end;
                padding: 0 2px;
            }
            span{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 2;
                // padding-right: 0.3rem;
                padding: 0 0.2rem;
            }
            .variance-container{
                float: left;
                // max-width: 44px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.text-red {
                    color: #ff0000;
                }
            }
       }
    }
  }
  .ag-floating-bottom {
    overflow: auto !important;
    .ag-floating-bottom-container {
        .table-row-wrapper .table-cell {
                color: #3890ff;
                font-weight: bold;
                display: flex;
                // justify-content: flex-start;
                align-items: center;
                //font-size: 9px;
                box-sizing: content-box;
          }
      }
  }

.ag-theme-balham .ag-header-cell{
    // font-size: 10px;
    // display: flex;
    // align-items: center;
    // padding: 0 0 0 0.5rem;
    // letter-spacing: 0.6px;
    // opacity: 0.8;
    // background-color: #f2f5f9 !important;
    &::after {
        border: none;
    }
}
// .ag-root-wrapper-body.ag-layout-normal {
//     height: 100% !important;
// }

// View full screen styles

.full-screen-records {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 14px;
    line-height: 1.2 !important;
    color: #5b6786;
    text-align: right;
}
.full-screen-records-name {
    font-size: 14px;
    line-height: 1.2 !important;
    color: #5b6786;
}

.mat-menu-panel {
    .mat-menu-item {
        line-height: 2.5;
        display: flex;
        height: auto;
        align-items: center;
        font-size: 14px;
        color: #5b6786;
        i {
            font-size: 14px;
            color: #5b6786;
            line-height: 1;
            display: flex;
            align-items: center;
        }
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        // background-image: linear-gradient(to top, #f2f4f7, #ffffff);
        // background-image: linear-gradient(to top, #223854, #1a2c44);
    }
    .mat-form-field-outline-thick {
        // color: #dfdfdf !important;
    }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #3890ff !important;
    background-color: #f1f4f8;
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #3890ff;
}

.mat-checkbox-frame {
    border-color: #5B6786 !important;
}

.mat-checkbox-disabled.mat-checkbox-checked, 
.mat-checkbox-disabled.mat-checkbox-indeterminate {
    .mat-checkbox-background {
        background-color: #e9edf1 !important;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.1);
    }
    .mat-checkbox-frame {
        border-style: none !important;
    }
    .mat-checkbox-checkmark-path {
        stroke: #9ca9bb !important;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 0 !important;
}
.mat-form-field-underline {
    background-color: transparent !important;;
}
.mat-form-field-ripple {
    background-color: transparent !important;
}

@media screen and (min-width: 1441px) {
    .top-grid {
       .table-row-wrapper {
          .table-cell {
               font-size: 1.3rem;
               line-height: 1;
          }
       }
       .ag-header {
        .ag-header-row {
           .ag-header-cell {
               &:first-child {
                   .ag-header-cell-label {
                       justify-content: flex-start;
                       padding-left: 6px;
                   }
               }
               .ag-header-cell-label {
                   justify-content: flex-end;
                   white-space: initial;
                   text-align: right;
                   .ag-header-cell-text {
                       text-align: right;
                   }
               }
           }
        }
        }

        .table-row-wrapper {
           .table-cell {
                padding: 0 0 0 6px;
                &.values {
                    &.number-cell {
                        padding: 0 5px;
                    }
                    &:last-child {
                        padding-right: 6px;
                    }
                }
           }
        }

     }
     .ag-theme-balham .ag-header-cell{
         font-size: 12px;
         padding-left: 1rem;
     }    
    .ag-floating-bottom {
        .ag-floating-bottom-container {
            .table-row-wrapper .table-cell {
                font-size: 1.3rem;
            }
        }
    }
}

.dashlet-table-custom {
    // height: 90%;
    width: 100%;
    height: 100%;
}
.full-screen-wrapper, .risk-details-wrapper, .renewal-wrapper {
    .dashlet-table-custom {
        height: 100%;
        width: 100%;
    }
}
@media screen and (min-width: 1441px) {
     .dashlet-wrapper{
        .dashlet-table-custom {
            height: 100%;
            width: 100%;
        }
     } 
     .ag-center-cols-viewport {
        overflow: hidden !important;
      }
     .full-screen-wrapper, .risk-details-wrapper, .renewal-wrapper {
        .dashlet-table-custom {
            height: 100%;
            width: 100%;
        }
    }
}

// Okta css changes 

#okta-sign-in.auth-container.main-container {
    margin: 0 !important;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.35);
    border: none;
    box-shadow: none;
    .okta-form-label, .custom-checkbox label {
        color: #ffffff;
    }
}
#okta-sign-in.auth-container h2 {
    display: none !important;
}
#okta-sign-in.auth-container .okta-sign-in-header {
    border: none !important;
    padding-bottom: 0px !important;
    margin-top: 20px !important;
}
#okta-sign-in.auth-container .okta-form-input-field {
    background-color: black !important;
    border-radius: 4px !important;
    border: 1px solid black !important;
    input {
        color: #ffffff !important;
    }
}
input:-internal-autofill-selected {
    background-color: rgb(0, 0, 0) !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, black) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

#okta-sign-in.auth-container .link:link, 
#okta-sign-in.auth-container .link:visited, 
#okta-sign-in.auth-container .link:hover, 
#okta-sign-in.auth-container .link:active {
    color: #ffffff;
}

#okta-sign-in.auth-container .button-primary {
    background: linear-gradient(#0010f8, #0010f8);

    // background-color: #0010f8;
    border-radius: 4px;
    border: 1px solid #0010f8;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    &:hover {
        transition: all 0.2s !important;
        background: linear-gradient(#0010f8, #0010f8);
        border-radius: 4px;
        border: 1px solid #0010f8;
    }
}
#okta-sign-in .auth-footer {
    text-align: center !important;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:4px solid #fff
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {display:none}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .sidenav, .sideNav.open,
    .cdk-overlay-container 
    .cdk-overlay-backdrop + .cdk-global-overlay-wrapper{
        background-color: rgba(242, 245, 249, 0.8) !important;
    }
    .toggle-knob i.table {
        margin-left: -3px !important;
    }
    .mat-select-panel-wrap .multiselect-dropdown.mat-select-panel {
        min-width: 116% !important;
    }
}

@media only screen and (min-width: 2388px) { 
    /* Your Styles... */ 
    html {
        font-size: 100% !important;
    }
    .main-wrapper {
        height: 94% !important;
    }
    .exhibit-container, .gwp-container {
        flex: 1;
    }
    .hbu-container {
        height: 25% !important;
    }
    app-stats {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .filter-wrapper .filter-section-wrapper .filter-section-container {
        height: 88% !important;
    }
    // app-dashlet-tabs .dashlet-options-section {
    //     height: 8% !important;
    // }
    .exhibits-wrapper {
        height: 62% !important;
    }
    .dashlet-container {
        height: 34% !important;
    }
    .mat-tab-header .mat-tab-label-container .mat-tab-label.mat-tab-label-active {
        font-size: 1.5rem !important;
    }
    .highchart-wrapper{
        height: 800px !important;
    }
    .select-dropdown-wrapper {
        left: -3rem !important;
        width: 80% !important;
    }
}
@media only screen and (min-width: 2731px) { 
    html, body {
                font-size: 100% !important;
            }
    .select-dropdown-wrapper {
        position: relative;
        top: -0.6rem !important;
        right: 1rem !important;
    }
    .industry-product-tab .dashlet-options-section {
        height: 10% !important;
        margin-bottom: 1.5rem;
    }
}
 
@media screen and (max-width: 1441px) {
    html, body {
        font-size: 40% !important;
    }
    app-select-dropdown.sel-drop-dwn {
        width: 50% !important;
    }
    .top-grid .table-row-wrapper .table-cell span {
        padding: 0 0.5rem !important;
    }
    .view-full-screen-container .top-grid 
    .ag-header .ag-header-row .ag-header-cell {
        padding-left: 1rem !important;
    }
    .full-screen-dialog-wrapper .export-filter-wrapper {
        padding: 15px 8px;
    }
    .top-grid .table-row-wrapper .table-cell {
        font-size:1.4rem !important;
    }
    .bar-container, .dashlet-data-display-section  {
        tspan {
            font-size:1.4rem !important;
        }
    }
}

@media screen and (min-width: 1500px) and (max-width: 1536px) {
    html,body {
        font-size: 50%;
    }
    app-select-dropdown.sel-drop-dwn {
           width: 48% !important;
    }
}
@media screen and (min-width: 1366px) and (max-width: 1441px)  {
    html, body {
        font-size: 43% !important;
    }
    // app-select-dropdown.sel-drop-dwn {
    //     width: 48% !important;
    // }
}
@media screen and(min-width: 1000px) and (max-width: 1095px){
    html, body {
    font-size: 33% !important;
    }
}
